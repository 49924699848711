


























































import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';

@Component({
  components: {},
})
export default class PoqConnectPage extends Vue {
  public baseUrl = process.env.BASE_URL;
  public status = '';
  public startLink = false;
  public linked = false;
  public linkFailed = false;
  public timeLeft = 2;
  public get showRouter() {
    return this.pageReady === 'failed' || this.pageReady === 'done';
  }

  public width = 1200;
  public contentStyle = { transform: 'scale(1)' };
  public mounted() {
    if (!(this.$el as any).resizeSensor) {
      const res = new ResizeSensor(this.$el, this.onResize);
    }

    this.onResize();
  }
  public beforeDestroy() {
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
    }
  }
  public onResize() {
    this.width = this.$el.clientWidth;
    this.contentStyle = {
      transform: 'scale(' + Math.min(1, this.width / 600) + ')',
    };
  }

  public get pageReady() {
    if (this.$route.query.error as string) {
      return 'failed';
    } else if (userx.loginStatus === 'loading') {
      return 'auth';
    } else if (userx.loginStatus === 'logged in') {
      if (!this.linked) {
        if (!this.startLink) {
          this.startLink = true;
          const code = (this.$route.query.code as string) || '';
          userx
            .linkPoq({ code, redirectUri: 'https://stabfish2.io/poq-connect' })
            .then(() => {
              this.linked = true;
            })
            .catch(() => {
              this.linkFailed = true;
            });
        }
        if (this.linkFailed) {
          return 'failed';
        }
        return 'linking';
      }
      return 'done';
    } else if (userx.loginStatus === 'logged out') {
      return 'redirect';
    }
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    if (newVal === 'auth') {
      this.status = 'Logging In...';
    } else if (newVal === 'linking') {
      this.status = 'Connecting POQ account...';
    } else if (newVal === 'failed') {
      this.status = 'POQ account connection failed.';
    } else if (newVal === 'done') {
      this.status = 'POQ account connected.';
    } else {
      this.status = '';
    }
    if (newVal === 'redirect') {
      this.status = 'You are logged out.';
    }

    if (
      this.pageReady === 'redirect' ||
      this.pageReady === 'failed' ||
      this.pageReady === 'done'
    ) {
      this.timeLeft = 2;
      setInterval(() => {
        if (this.timeLeft === 0) {
          window.close();
        } else {
          this.timeLeft--;
        }
      }, 1000);
    }
  }
}
